/* TODO an exact copy of ./ParcelDetailMap.css -> should be abstracted away */
#as-applied-detail-map {
    background-color: grey
}

#as-applied-detail-map .ol-zoom {
    left: unset;
    top: unset;
    right: 15px;
    bottom: 28px;
}

#as-applied-detail-map .ol-attribution {
    font-size: 11px;
}

#as-applied-detail-map .ol-control,
#as-applied-detail-map .ol-control:hover {
    padding: 0px;
    background: none;
}

#as-applied-detail-map .ol-overlaycontainer-stopevent .ol-zoom button,
#as-applied-detail-map .ol-control button,
#as-applied-detail-map .ol-control button:focus
{
    color: black;
    background-color: #FFFFFF;
    width: 32px;
    height: 32px;
    margin: 0px;
    border-radius: 4px;
}

#as-applied-detail-map .ol-control button.ol-zoom-in {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

#as-applied-detail-map .ol-control button.ol-zoom-out {
    height: 33px;
    border-top: 1px solid #e0e0e0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

#as-applied-detail-map .ol-overlaycontainer-stopevent .ol-zoom button:hover,
#as-applied-detail-map .ol-control button:hover {
    color: #00A179;
}

#as-applied-detail-map .ol-overlay-container .hover-hint{
    border-radius: 4px;
    padding: 5px 15px;
}

#as-applied-detail-map .ol-overlay-container .hover-hint p{
    font-weight: bold;
    margin: 0;
    font-size: 12px;
    text-align: center;
}

#as-applied-detail-map .ol-overlay-container .parcel-area-info{
    background-color: #FCD783;
}

#as-applied-detail-map .ol-overlay-container .parcel-area-info p{
    color: #000000;
}

#as-applied-detail-map .ol-overlay-container .parcel-area-info p:last-child{
    font-weight: normal;
}

#as-applied-detail-map .ol-overlay-container .parcel-area-info p:first-child{
    font-weight: bold;
}

#as-applied-detail-map .ol-overlay-container .parcel-disabled-info{
    background-color: #333333;
}

#as-applied-detail-map .ol-overlay-container .parcel-disabled-info p{
    color: #ffffff;
}
